import { template as template_ebb1c3426e7a4d3f9817b9f8b60b9c28 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ebb1c3426e7a4d3f9817b9f8b60b9c28(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
