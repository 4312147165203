import { template as template_263ed3ef76db43368068145112eaab0b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_263ed3ef76db43368068145112eaab0b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
