import { template as template_604e3d1ab4324f37bf4e6fee68d6fbc4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_604e3d1ab4324f37bf4e6fee68d6fbc4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
