import { template as template_532eb3099dab42ac91722418a3cb809d } from "@ember/template-compiler";
const FKLabel = template_532eb3099dab42ac91722418a3cb809d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
